import Notify from "devextreme/ui/notify"

export const uploadURL = "https://mvc.cosmospro.com.br/uploads/"

export const TPC_Lista_Status_Contas_CRM= "TPC_Lista_Status_Contas_CRM"


export const URLCustonView = (customView: string) => {
  return "https://portal.cosmospro.com.br:9191/odata/CustomViews(Name='" + customView + "')/ExecuteAndReceiveData()"
}
export const URLCustonAction = (custonAction: string) => {
  return "https://portal.cosmospro.com.br:9191/api/ExecuteCustomAction/ExecuteAction?ActionName=" + custonAction
}

export const staticToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjQxNzQzMjM1REFEQjgzQzExNTA4MEYxNjk2NjJCNzU0IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2OTAyMzQ0NTAsImV4cCI6MTY5MDIzODA1MCwiaXNzIjoiaHR0cHM6Ly9hdXRoLmNvc21vc3Byby5jb20uYnIiLCJhdWQiOiJodHRwczovL2F1dGguY29zbW9zcHJvLmNvbS5ici9yZXNvdXJjZXMiLCJjbGllbnRfaWQiOiJKYXZhc2NyaXB0Q2xpZW50Iiwic3ViIjoiMTQ3NDciLCJhdXRoX3RpbWUiOjE2OTAyMzM1MTQsImlkcCI6ImxvY2FsIiwiTG9naW5TZXNzaW9uSUQiOiI3Nzc3ZTU1YS05MzAyLTQxOGUtODY3NS0yYmJhMzBlYzdmM2QiLCJOYW1lIjoicHJvY2ZpdC5qYkBwcm9jZml0LmNvbS5iciIsIkZyaWVuZGx5TmFtZSI6Ik5hesOhcmlhIERpc3RyaWJ1aWRvcmEiLCJUZW5hbnRJZCI6IjEwMDI1IiwiUHJvZmlsZSI6IjEiLCJKb2JSb2xlIjoiNjQiLCJJbml0aWFscyI6Ik5EIiwiVXNlcklkZW50aWZpY2F0aW9uQ2hhdCI6IlRJZC0xMDAyNTpVSWQtMTQ3NDciLCJVc2VyQ2hhdFNpZ25hdHVyZSI6IjQ3NjA4OGFlNDg0ODA3NDQwMWZmZGJhYmEzODBkMjA0NWE2ZjI4MGMzNGMxOTUzNWU3MGYwYjg4YjhjMGM4NzUiLCJVc2VyUGljdHVyZSI6IjM5MmFmNDRkLWJmOTMtNDU3OC1iMWVjLTdjNzMyYzc2ZDhiZi5qcGciLCJyb2xlIjoiYWRtaW4iLCJMb2dvIjoiYWMzNGQxZWMtNDM3Ni00NDQxLWE0ZDAtN2JjZjczYTQ3NGViLnBuZyIsIk1haW5Db2xvciI6IiM0Nzc4YjgiLCJGb250Q29sb3IiOiIjZmZmZmZmIiwiVGVuYW50TmFtZSI6Ik5hesOhcmlhIERpc3RyaWJ1aWRvcmEiLCJUZW5hbnRLZXkiOiJCWlAiLCJCcmFuY2giOiI5IiwiRW1haWwiOiJwcm9jZml0LmpiQHByb2NmaXQuY29tLmJyIiwianRpIjoiOEM3NTdDQjk0RUVERjUwRDA3OUExNjk1MUUxMTFCMUMiLCJzaWQiOiI5MDAxQzMyQjQzN0JCMjQ5OEIyNTg5MkRDOEJDQjFCNSIsImlhdCI6MTY5MDIzNDQ1MCwic2NvcGUiOlsib3BlbmlkIiwicHJvZmlsZSIsImVtYWlsIiwiV0VCQVBJUE9SVEFMIiwiQ29zbW9zUHJvV2ViQXBpIiwiSWRlbnRpdHlTZXJ2ZXJBcGkiLCJvZmZsaW5lX2FjY2VzcyJdLCJhbXIiOlsicHdkIl19.JXCdAUnWklYM4bqk09cEmOP0UmBrJaxtSXq_njLksseTbMXIsAucNMc1hkRHOYxTUCMKwQERWZ7Qt2gx4tylGOLA7HkyzblqdjnYVFIn3oRaLXL4uuxRINh-92s0yNuJoIEsRAVy8fXp9HYOtdYSAf7Y54qk4rLwgw2I1fI6GPYbBsjseKXYi5HiFfoTQ45PUYrApdZEuDh1eUS5VbfrvC4p7GHKPPgOm28VQWi79FvMFctacGycXPoW4eyS05no83WzgIKuQYhOH0gcavba9eJi0pW5N-Et9KUuClhm-5JfC3wewcCssmYxVcTfj7SijvF8gzfREhMPQ9-FL1mGBw'

export const grant_type='password';
export const scope= 'offline_access WEBAPIPORTAL CosmosProWebApi profile openid'
export const client_id= 'EPedidos'
export const client_secret= '0edf2f37-70a0-4f38-873d-a9fbf5dfac53'


export function getHeaders(token:string){
  let newToken = token
  return {
    headers: {
      'Authorization': `Bearer ${newToken}`,
      'api-version': 1,
    }
  }
}
export function notifyChange(message:any,type:any){ 
  return Notify({ message: message, height: 45, width: 300, position: {my: "top right", at: "top right", offset: "-10 15"}}, type) 
}
export const sendLoadCompleteMessage = () => {
  window.parent.postMessage({ action: "loadComplete" }, "*");
};