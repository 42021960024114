export const getUserToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload)
}

export function getRefreshToken() {
  return localStorage.getItem("refresh_token");
}
export function getAccessToken() {
  return localStorage.getItem("access_token");
}
export function getUserId() {
  return  Number(localStorage.getItem("user-id"));
}
export function logoutAplication() {
  localStorage.removeItem("user-id");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}