
import './App.css';
import 'devextreme/dist/css/dx.light.css';
import RouterPage from "./routes/RouterPage";

function App() {
  return (
    <RouterPage/>
  )
}

export default App