export default function Error() {
    return (
        <div className='view'>
            <img src={"./img/logo-nazaria-white.png"} />

            <div className="erro-text">

                <h1>Error 404 Not Found</h1>
                <p>Não foi encontrado a página</p>
            </div>

        </div>

    )
}