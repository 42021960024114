import React, { Component } from "react";
import { getUserId } from "../../token/UserToken";

interface VanillaWrapperProps {
    token: string
}

export default class VanillaWrapper extends Component<VanillaWrapperProps> {
    public shadowHost: any = null;
    constructor(props: VanillaWrapperProps) {
        super(props);
        this.shadowHost = React.createRef();
        //useRef<HTMLDivElement>(null) 
    }
    componentDidMount(): void {
        const CLASSE = this;
        //const browserWindow = useRef([window]); 
        console.log("teste", window, CLASSE.shadowHost.current)
        const shadowRoot = CLASSE.shadowHost.current.attachShadow({ mode: "open" });
        shadowRoot.innerHTML = `
            <link href="https://cdnjs.cloudflare.com/ajax/libs/devextreme/22.2.3/css/dx.common.css" rel="stylesheet" /> 
            <link href="https://cdnjs.cloudflare.com/ajax/libs/devextreme/22.2.3/css/dx.light.compact.css" rel="stylesheet" /> 
            <link href="./epedidos-web/epedidos.9973fb45e7d6fa970e1f.css" rel="stylesheet" /> 
            <div id="wrapper-epedidos"> 
            </div>
        `;
        // const fragment = document.createDocumentFragment(); 
        // const el2 = document.createElement("script"); 
        // el2.text = `
        //     const APP_TOKEN = "b863d4d5-589c-456f-ba25-34a33440913a"; 
        //     const APP_LOGOTIPO = "https://bibliotecas.cosmospro.com.br/cosmos/rede-nossa-farma/mmc/img/logo-rede-nossa-farma-64.png"; 
        //     const COMPANY_UID = "BZP"; const USER_ID = 14747; const COMPANY_ID = 1; 
        //     const COMPANY_LOGO = "B46B44207EA268EDC270134B058CD3B6.png"; 
        //     const IS_OFFICIAL = true; 
        //     const APP_PORTAL_URL = "https://portal.cosmospro.com.br:9191";
        // `; 
        // fragment.appendChild(el2);

        setTimeout(() => {
            let element= shadowRoot.querySelector("#wrapper-epedidos")
            // element.style.minHeight = "100%";
            element.style.display = "flex";
            element.style.flexDirection = "column";
            element.style.flexGrow = "1";

            // console.log("setTimeout",element)
            // const fnLogout: any = (window as any)["logout"];
            const fnInicializa: any = (window as any)["inicializar"];
            // fnInicializa(axios); 
            // fnInicializa(this.props.token); 
            
            fnInicializa({
                "appToken": CLASSE.props.token,
                "appLogotipo": "https://bibliotecas.cosmospro.com.br/cosmos/rede-nossa-farma/mmc/img/logo-rede-nossa-farma-64.png",
                "companyUid": "BZP",
                "userId":getUserId(),
                "companyId": 1,
                "companyLogo": "B46B44207EA268EDC270134B058CD3B6.png",
                "isOfficial": true,
                "appPortalUrl": "https://portal.cosmospro.com.br:9191"
            });
            const fnRender: any = (window as any)["renderApp"];
            // const fnReRender: any = (window as any)["reRenderApp"];
            setTimeout(() => {fnRender(element);},2000)
        }, 1000);
        
    }
    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void { 
        console.log("prevProps", prevProps, prevState) 
    }
    shouldComponentUpdate(nextProps: Readonly<{[key:string]:any}>, nextState: Readonly<{}>, nextContext: any): boolean { 

        const fnInicializa: any = (window as any)["inicializar"];
        fnInicializa({
            "appToken": nextProps["token"],
            "appLogotipo": "https://bibliotecas.cosmospro.com.br/cosmos/rede-nossa-farma/mmc/img/logo-rede-nossa-farma-64.png",
            "companyUid": "BZP",
            "userId":getUserId(),
            "companyId": 1,
            "companyLogo": "B46B44207EA268EDC270134B058CD3B6.png",
            "isOfficial": true,
            "appPortalUrl": "https://portal.cosmospro.com.br:9191"
        });

        return false; 
    }
    componentWillUnmount(): void { console.log("componentWillUnmount" ) } 

    render() { 
        return <div ref={this.shadowHost} className="shadowHost"></div>; 
    }
} 

// export default VanillaWrapper;