import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import  Epedidos from '../pages/Epedidos/Epedidos'
import  Login from '../pages/Login/Login'
import  Error from "../pages/Error/Error";
  
const PrivateRoute = () => {
  const token = localStorage.getItem("access_token");
  // console.log("PrivateRoute",window.TOKEN);
  return token ? <>{<Epedidos />}</> : <Navigate to="/" />;
};

const PrivateRouteEpedidos = () => {
  const token = localStorage.getItem("access_token");
  // console.log("PrivateRoute",window.TOKEN);
  return token ? <Navigate to="epedidos" relative='route' /> : <Login />;
};

const PrivateRouteErro = () => {
  const token = localStorage.getItem("access_token")
  return !token ? <Login/>:<Error />;
};

export default function RouterPage() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRouteEpedidos />} />
        <Route path="/epedidos" element={<PrivateRoute />} />
        <Route path="*" element={<PrivateRouteErro />} />
      </Routes>
    </BrowserRouter>
  );
};